<template>
  <b-card header="Tipologia Strutturale">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">
            <cs-select
              id="strutturale.tipologia"
              v-model="anagrafica.strutturale.tipologia"
              :options="optsTipologia"
              :reduce="r => r.id"
              label="Tipologia"
              @input="enableAltro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="strutturale.altro"
              v-model="anagrafica.strutturale.altro"
              :read-only="!isAltroEnabled"
              label="Altro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="strutturale.materiale"
              v-model="anagrafica.strutturale.materiale"
              label="Materiale costruttivo"
            />
          </b-col>
        </b-row>

      </b-form>

    </b-card-body>
  </b-card>

</template>

<script>

const TPSTRUTTURALE = Object.freeze({
  A: { id: 1, label: 'Arco in Muratura' },
  B: { id: 2, label: 'Travate appoggiate' },
  C: { id: 3, label: 'Travate continue' },
  D: { id: 4, label: 'Soletta in C.A.' },
  E: { id: 5, label: 'Sezione tubolare in c.a.' },
  F: { id: 6, label: 'Arco in C.A.' },
  G: { id: 7, label: 'Travate Gerber' },
  H: { id: 8, label: 'Cassone in Precompresso' },
  I: { id: 9, label: 'Sezione tubolare in acciaio' },
  L: { id: 10, label: 'Arco in acciaio' },
  M: { id: 11, label: 'Strallato o sospeso' },
  N: { id: 12, label: 'Travate in c.a.p. a cavi post-tesi' },
  ALTRO: { id: 13, label: 'Altro' },
})

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsTipologia: Object.values(TPSTRUTTURALE),
      isAltroEnabled: false,
    }
  },
  methods: {
    enableAltro(params) {
      if (params === TPSTRUTTURALE.ALTRO.id) {
        this.isAltroEnabled = true
      } else {
        this.isAltroEnabled = false
        this.anagrafica.strutturale.altro = null
      }
    },
  },
}
</script>
