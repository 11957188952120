<template>
  <b-card header="Pile">
    <b-card-body>
      <b-form @submit.prevent="">

        <fieldset class="mb-3">
          <b-row>
            <b-col cols="6">
              <cs-input
                id="pile.tpSezione"
                v-model="anagrafica.pile.tpSezione"
                label="Tipologia sezione"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="pile.geometriaSezione"
                v-model="anagrafica.pile.geometriaSezione"
                label="Geometria sezione (circolare, rettangolare, etc.):"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <cs-input
                id="pile.tpFondazioni"
                v-model="anagrafica.pile.tpFondazioni"
                label="Tipologia fondazioni"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="pile.nrFondazioni"
                v-model="anagrafica.pile.nrFondazioni"
                type="number"
                label="Numero fondazioni "
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <cs-input
                id="pile.altezzaPile"
                v-model="anagrafica.pile.altezzaPile"
                type="number"
                step=".01"
                label="Altezza pile [m]"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="pile.evoluzione"
                v-model="anagrafica.pile.evoluzione"
                label="Evoluzione eventuale rispetto al fondo alveo"
              />
            </b-col>
          </b-row>
        </fieldset>
        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Materiale Costruttivo
          </legend>
          <b-row>
            <b-col cols="12">
              <cs-select
                id="pile.materialeCostruttivo"
                v-model="anagrafica.pile.materialeCostruttivo"
                label="Materiale costruttivo"
                :options="optsMtCostruttivo"
                :reduce="r => r.id"
                @input="enableAltro"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="pile.altroMaterialeCostruttivo"
                v-model="anagrafica.pile.altroMaterialeCostruttivo"
                label="Altro materiale costruttivo"
                :read-only="!isAltroEnabled"
              />
            </b-col>
          </b-row>
        </fieldset>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

const MTCOSTRUTTIVO = Object.freeze({
  A: { id: 1, label: 'Muratura' },
  B: { id: 2, label: 'C.a.' },
  C: { id: 3, label: 'C.A.P.' },
  D: { id: 4, label: 'Acciaio' },
  E: { id: 5, label: 'Misto (C.a./acciaio)' },
  F: { id: 6, label: 'Legno' },
  ALTRO: { id: 7, label: 'Altro' },
})

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsMtCostruttivo: Object.values(MTCOSTRUTTIVO),
      isAltroEnabled: false,
    }
  },
  methods: {
    enableAltro(params) {
      if (params === MTCOSTRUTTIVO.ALTRO.id) {
        this.isAltroEnabled = true
      } else {
        this.isAltroEnabled = false
        this.anagrafica.pile.altroMaterialeCostruttivo = null
      }
    },
  },
}
</script>
