<template>
  <b-card header="Impalcati">
    <b-card-body>
      <b-form @submit.prevent="">

        <fieldset class="mb-3">
          <b-row>
            <b-col cols="12">
              <cs-input
                id="impalcati.tracciato"
                v-model="anagrafica.impalcati.tracciato"
                label="Tracciato"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <cs-input
                id="impalcati.larghezzaCarreggiata"
                v-model="anagrafica.impalcati.larghezzaCarreggiata"
                type="number"
                step=".01"
                label="Larghezza carreggiata [m]"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="impalcati.larghezzaFuoriTutto"
                v-model="anagrafica.impalcati.larghezzaFuoriTutto"
                type="number"
                step=".01"
                label="Largheza fuori tutto"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <cs-check-box
                id="impalcati.sistemiProtezione"
                v-model="anagrafica.impalcati.sistemiProtezione"
                :inline="false"
                label="Sistemi di protezione"
                @input="resetTpSistemaProtezione"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="impalcati.tpSistemaProtezione"
                v-model="anagrafica.impalcati.tpSistemaProtezione"
                label="Tipo sistemi di protezione"
                :read-only="!anagrafica.impalcati.sistemiProtezione"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <cs-input
                id="impalcati.tpApparecchiAppoggio"
                v-model="anagrafica.impalcati.tpApparecchiAppoggio"
                label="Tipologia apparecchi di appoggio"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="impalcati.tpSistemiAntisismici"
                v-model="anagrafica.impalcati.tpSistemiAntisismici"
                label="Tipologia dispositivi antisismici"
              />
            </b-col>
          </b-row>
        </fieldset>
        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Materiale Costruttivo
          </legend>
          <b-row>
            <b-col cols="12">
              <cs-select
                id="impalcati.materialeCostruttivo"
                v-model="anagrafica.impalcati.materialeCostruttivo"
                label="Materiale costruttivo"
                :options="optsMtCostruttivo"
                :reduce="r => r.id"
                @input="enableAltroMtCostruttivo"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="impalcati.altroMaterialeCostruttivo"
                v-model="anagrafica.impalcati.altroMaterialeCostruttivo"
                label="Altro materiale costruttivo"
                :read-only="!isAltroMtCostruttivoEnabled"
              />
            </b-col>
          </b-row>
        </fieldset>
        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Tipologia soletta
          </legend>
          <b-row>
            <b-col cols="12">
              <cs-select
                id="impalcati.tpSoletta"
                v-model="anagrafica.impalcati.tpSoletta"
                label="Tipologia soletta"
                :options="optsTpSoletta"
                :reduce="r => r.id"
                @input="enableAltroTpSoletta"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="impalcati.altroTpSoletta"
                v-model="anagrafica.impalcati.altroTpSoletta"
                label="Altra tipologia soletta"
                :read-only="!isAltroTpSolettaEnabled"
              />
            </b-col>
          </b-row>
        </fieldset>

      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>

const MTCOSTRUTTIVO = Object.freeze({
  A: { id: 1, label: 'Muratura' },
  B: { id: 2, label: 'C.a.' },
  C: { id: 3, label: 'C.A.P.' },
  D: { id: 4, label: 'Acciaio' },
  E: { id: 5, label: 'Misto (C.a./acciaio)' },
  F: { id: 6, label: 'Legno' },
  ALTRO: { id: 7, label: 'Altro' },
})
const TPSOLETTA = Object.freeze({
  A: { id: 1, label: 'Muratura' },
  B: { id: 2, label: 'C.a.' },
  C: { id: 3, label: 'C.A.P.' },
  D: { id: 4, label: 'Acciaio' },
  E: { id: 5, label: 'Legno' },
  ALTRO: { id: 6, label: 'Altro' },
})

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsMtCostruttivo: Object.values(MTCOSTRUTTIVO),
      isAltroMtCostruttivoEnabled: false,
      optsTpSoletta: Object.values(TPSOLETTA),
      isAltroTpSolettaEnabled: false,
    }
  },
  methods: {
    enableAltroMtCostruttivo(params) {
      if (params === MTCOSTRUTTIVO.ALTRO.id) {
        this.isAltroMtCostruttivoEnabled = true
      } else {
        this.isAltroMtCostruttivoEnabled = false
        this.anagrafica.impalcati.altroMaterialeCostruttivo = null
      }
    },
    enableAltroTpSoletta(params) {
      if (params === TPSOLETTA.ALTRO.id) {
        this.isAltroTpSolettaEnabled = true
      } else {
        this.isAltroTpSolettaEnabled = false
        this.anagrafica.impalcati.altroTipologiaSoletta = null
      }
    },
    resetTpSistemaProtezione(params) {
      if (params === false) {
        this.anagrafica.impalcati.tpSistemaProtezione = null
      }
    },
  },
}
</script>
