<template>
  <b-tabs
    class="cs-tabs"
    card
    justified
  >
    <cs-tab
      :show="!loading && canAnagrafica"
      :is-loading="loading"
      text="Anagrafica"
    >
      <anagrafica
        :opera="opera"
      />
    </cs-tab>
    <cs-tab
      :show="!loading && canDrive"
      :is-loading="loading"
      text="Drive"
    >
      <drive
        :opera="opera"
      />
    </cs-tab>
    <cs-tab
      :show="!loading && canBim"
      :is-loading="loading"
      text="Bim"
    >
      <bim :opera="opera" />
    </cs-tab>
    <cs-tab
      :show="!loading && canParziali"
      :is-loading="loading"
      active
      text="Parziali"
    >
      <parziali :opera="opera" />
    </cs-tab>

  </b-tabs>
</template>

<script>
import Anagrafica from './anagrafica/Anagrafica.vue'
import Parziali from './parziali/Elenco.vue'
import Drive from './drive/Drive.vue'
import Bim from './bim/Bim.vue'

export default {
  components: {
    Anagrafica,
    Drive,
    Parziali,
    Bim,
  },
  data() {
    return {
      loading: true,
      opera: {
        parziali: [],
        documenti: [],
      },
      canAnagrafica: this.$grants.OPERE_ANAGRAFICA_VISUALIZZA.can,
      canDrive: this.$grants.OPERE_DOCUMENTI_ELIMINA.can
          || this.$grants.OPERE_DOCUMENTI_AGGIUNGI.can
          || this.$grants.OPERE_DOCUMENTI_SCARICA.can,
      canParziali: this.$grants.OPERE_PARZAILI_VISUALIZZA.can,
      canBim: this.$grants.OPERE_BIM_VISUALIZZA.can,
    }
  },
  created() {
    if (!this.$grants.OPERE_VISUALIZZA_DETTAGLIO.can) this.$routing.ERRORE401.push()

    const { id } = this.$route.params
    this.$remote.opere.single(id)
      .then(res => {
        this.setBreadcrumbData(this.$store, this.$route.name, { id: res.data.id, nome: res.data.nome })

        this.opera = res.data
        this.loading = false
      })
      .catch(res => {
        this.showReposonseMessage(res)
        this.$routing.ERRORE404.push()
      })
  },
}
</script>
