<template>

  <b-overlay :show="loading">
    <b-card
      header="Azioni"
    >
      <b-card-body
        style="padding-bottom: 0"
        class="pl-0"
      >
        <b-button-group>
          <b-button
            v-if="canSalva"
            variant="primary"
            title="Salva Modifiche"
            @click="update"
          >
            <feather-icon icon="SaveIcon" />
          </b-button>
          <b-button
            variant="primary"
            title="Scarica PDF"
            @click="downloadPdf"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-button-group>
      </b-card-body>
    </b-card>
    <b-tabs
      pills
      vertical
      class="cs-tabs"
      nav-wrapper-class="nav-vertical"
      card
    >
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Opera"
      >
        <opera
          style="margin-left: 20px"
          :opera="opera"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Infrastruttura"
      >
        <infrastruttura
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Localizzazione"
      >
        <localizzazione
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Informazioni Generali"
      >
        <generali
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Dati di Progetto"
      >
        <progetto
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="DL 22/02/2004, n. 42"
      >
        <decreto
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Classificazioni"
      >
        <classificazioni
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Dati Geomorfologici"
      >
        <morfologia
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Caratteristiche Geometriche"
      >
        <geometria
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Tipologia Strutturale"
      >
        <strutturale
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Spalle"
      >
        <spalle
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Pile"
      >
        <pile
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Impalcati"
      >
        <impalcati
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Giunti"
      >
        <giunti
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Interventi Strutturali"
      >
        <interventi
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Interventi Manutenzione"
      >
        <manutenzione
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Ispezioni Pregresse"
      >
        <ispezioni-pregresse
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Attività di Monitoraggio"
      >
        <monitoraggio
          style="margin-left: 20px"
          :anagrafica="anagrafica"
          :documenti="opera.documenti"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Rete Stradale"
      >
        <rete-stradale
          style="margin-left: 20px"
          :anagrafica="anagrafica"
          :documenti="opera.documenti"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Classi di conseguenza"
      >
        <classi-conseguenza
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
      <cs-tab
        :show="opera != null"
        :is-loading="opera == null"
        text="Rischi"
      >
        <rischi
          style="margin-left: 20px"
          :anagrafica="anagrafica"
        />
      </cs-tab>
    </b-tabs>
  </b-overlay>

</template>

<script>
import Opera from './sezioni/Opera.vue'
import Infrastruttura from './sezioni/Infrastruttura.vue'
import Localizzazione from './sezioni/Localizzazione.vue'
import Generali from './sezioni/Generali.vue'
import Progetto from './sezioni/Progetto.vue'
import Decreto from './sezioni/Decreto.vue'
import Classificazioni from './sezioni/Classificazioni.vue'
import Morfologia from './sezioni/Morfologia.vue'
import Geometria from './sezioni/Geometria.vue'
import Strutturale from './sezioni/Strutturale.vue'
import Spalle from './sezioni/Spalle.vue'
import Pile from './sezioni/Pile.vue'
import Impalcati from './sezioni/Impalcati.vue'
import Giunti from './sezioni/Giunti.vue'
import Interventi from './sezioni/Interventi.vue'
import Manutenzione from './sezioni/Manutenzione.vue'
import IspezioniPregresse from './sezioni/IspezioniPregresse.vue'
import Monitoraggio from './sezioni/Monitoraggio.vue'
import ReteStradale from './sezioni/ReteStradale.vue'
import ClassiConseguenza from './sezioni/ClassiConseguenza.vue'
import Rischi from './sezioni/Rischi.vue'

export default {
  components: {
    Opera,
    Infrastruttura,
    Localizzazione,
    Generali,
    Progetto,
    Decreto,
    Classificazioni,
    Morfologia,
    Geometria,
    Strutturale,
    Spalle,
    Pile,
    Impalcati,
    Giunti,
    Interventi,
    Manutenzione,
    IspezioniPregresse,
    Monitoraggio,
    ReteStradale,
    ClassiConseguenza,
    Rischi,
  },
  props: {
    opera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      canSalva: this.$grants.OPERE_ANAGRAFICA_MODIFICA.can,
      anagrafica: {
        tipologia: null,
        morfologia: null,
        infrastruttura: {
          tipologia: null,
          codice: null,
          nome: null,
        },
        localizzazione: {
          kmIniziale: null,
          kmFinale: null,
          provincia: null,
          regione: null,
          comune: null,
          localita: null,
          sismicita: null,
          zonaSismicita: null,
          tpCoordinateId: null,
          centro: {
            quotaSulMare: null,
            longitudine: null,
            latitudine: null,
          },
          iniziale: {
            quotaSulMare: null,
            longitudine: null,
            latitudine: null,
          },
          finale: {
            quotaSulMare: null,
            longitudine: null,
            latitudine: null,
          },
          fenomeniErosivi: null,
          fenomeniFranosi: null,
        },
        generali: {
          proprietario: null,
          concessionario: null,
          enteVigilante: null,
          dtCollaudo: {
            anno: null,
            tipologia: null,
          },
          dtInterventi: {
            anno: null,
            tipologia: null,
          },
          idGestoreProprietario: null,
          dtInizioLavori: null,
          dtFineLavori: null,
          dtEntrataEsercizio: null,
        },
        progetto: {
          progettista: null,
          normeDiProgetto: null,
          enteApprovatore: null,
          dtInizio: {
            anno: null,
            tipologia: null,
          },
          dtFine: {
            anno: null,
            tipologia: null,
          },
          dtApprovazione: {
            anno: null,
            tipologia: null,
          },
        },
        decreto: {
          provvedimentoTutela: null,
          autoreProgettazione: null,
          pianiPaesaggistici: null,
        },
        stato: {
          statoLg: null,
          statoAinop: null,
        },
        classificazioni: {
          clsCollegamento: null,
          clsStradale: null,
        },
        geometria: {
          luceComplessiva: null,
          larghezzaImpalcato: null,
          nrCampate: null,
          luceCampate: null,
          tracciato: null,
          superficeTotaleDx: null,
          superficieTotaleSx: null,
        },
        strutturale: {
          tipologia: null,
          altro: null,
          materiale: null,
        },
        spalle: {
          tpSpallaIniziale: null,
          tpSpallaFinale: null,
          fondSpallaIniziale: null,
          fondSpallaFinale: null,
        },
        pile: {
          tpSezione: null,
          tpFondazioni: null,
          altezzaPile: null,
          geometriaSezione: null,
          nrFondazioni: null,
          evoluzione: null,
          materialeCostruttivo: null,
          altroMaterialeCostruttivo: null,
        },
        impalcati: {
          tpSoletta: null,
          altroTpSoletta: null,
          materialeCostruttivo: null,
          altroMaterialeCostruttivo: null,
          tracciato: null,
          larghezzaFuoriTutto: null,
          sistemiProtezione: null,
          larghezzaCarreggiata: null,
          tpSistemaProtezione: null,
          tpApparecchiAppoggio: null,
          tpSistemiAntisismici: null,
        },
        giunti: {
          lunghezzaGiuntoPila: null,
          lunghezzaGiuntoSpalla: null,
          tipologia: null,
          numero: null,
          lunghezza: null,
        },
        interventi: {
          voltaMuratura: null,
          riparazioneElementiStrutturali: null,
          ampliamentoCarregiata: null,
          elementiStrutturaliAggiuntivi: null,
          interventiGeotecniciSpallePile: null,
          interventiMitigazioneSpallePile: null,
          altro: null,
        },
        manutenzioni: {
          stato: null,
          piano: null,
          numero: null,
          dtUltimo: null,
          periodicita: null,
          interventi: [],
        },
        ispezioniPregresse: {
          stato: null,
          programma: null,
          numero: null,
          dtUltima: null,
          periodicita: null,
          risultati: null,
          ispezioni: [],
        },
        monitoraggio: {
          stato: null,
          tpRilevamento: null,
          mtMonitoraggio: null,
          dtInizio: null,
          dtAggiornamento: null,
          dtFine: null,
          tpStrumentazione: null,
          grandezzeMisurate: null,
          risultatiSignificativi: null,
          livelloAllerta: null,
          allegato: null,
        },
        reteStradale: {
          itinerarioInternazionale: null,
          reteTen: null,
          reteEmergenza: null,
          nrCarregiate: null,
          nrCorsie: null,
          presenzaCurve: null,
          trafficoAvgGg: null,
          trafficoAvgGgVeicoliCmCarregiata: null,
          trafficoAvgGgVeicoliCmCorsia: null,
          limitazioneCarico: null,
          massaLimitazioneCarico: null,
          alternativeStradali: null,
          durataDeviazioneKm: null,
          durataDeviazioneMn: null,
          catgoriaPercorsoAlternativo: null,
          disponibilitaStudi: null,
          categoriaPonte: null,
          allegato: null,
        },
        classiConseguenza: {
          tipologia: null,
          giustificazione: null,
        },
        rischi: {
          idraulico: null,
          frane: null,
          altro: null,
        },
      },
    }
  },
  created() {
    this.loading = true
    this.$remote.opere.single_anagrafica(this.opera.id)
      .then(res => { this.anagrafica = res.data })
      .catch(res => this.showErrorMessage(res))
      .finally(() => { this.loading = false })
  },
  methods: {
    update() {
      this.loading = true
      this.opera.anagrafica = this.anagrafica
      this.$remote.opere.update(this.opera)
        .then(() => { this.showUpdateSuccessMessage() })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
    downloadPdf() {
      this.loading = true
      this.opera.anagrafica = this.anagrafica
      this.$remote.opere.download_anagrafica(this.opera.id)
        .then(res => {
          const nome = `Anagrafica ${this.opera.codice}.pdf`
          this.downloadFile(res.data, nome)
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
  },
}
</script>
