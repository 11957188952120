<template>
  <b-overlay>

    <b-row>
      <b-col :cols="canLoad ? '3' : '12'">
        <b-card
          v-if="isLoaded"
          header="Azioni"
        >
          <b-card-body class="pb-1">
            <b-button
              title="Espandi"
              variant="primary"
              @click="expand"
            >
              <feather-icon icon="Maximize2Icon" />
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        v-if="canLoad"
        :cols="isLoaded ? '9' : '12'"
      >
        <carica
          :opera="opera"
          @onAdd="add"
        />
      </b-col>
    </b-row>

    <b-card
      v-if="isLoaded"
      header="Visualizzatore Bim"
    >
      <b-card-body class="p-0">
        <iframe
          id="iframe"
          ref="iframe"
          :src="'https://v2.bim.cugri.it?id=' + opera.codice"
          style="width: 100%; min-height: 80vh; max-height: 100%; border: none;"
        />

      </b-card-body>
    </b-card>

  </b-overlay>
</template>

<script>

import Carica from './Carica.vue'

export default {
  components: {
    Carica,
  },
  props: {
    opera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: this.opera.isBimLoaded,
      canLoad: this.$grants.OPERE_BIM_CARICA.can,
    }
  },
  methods: {
    add(isLoaded) {
      if (isLoaded) {
        this.showAddSuccessMessage()
        this.isLoaded = true
      }
    },
    expand() {
      window.open(`https://v2.bim.cugri.it?id=${this.opera.codice}`, '_blank')
    },
  },
}
</script>
