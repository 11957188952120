<template>
  <b-card header="Opera">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">
            <cs-input
              id="codiceOperaGlobale"
              v-model="opera.codice"
              label="Codice Opera Globale"
              read-only
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">

            <cs-input
              id="codiceIop"
              v-model="opera.codiceIop"
              label="Codice IOP"
            />

          </b-col>
          <b-col cols="6">
            <cs-input
              id="codiceCup"
              v-model="opera.codiceCup"
              label="Codice CUP"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-input
              id="nome"
              v-model="opera.nome"
              label="Nome"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="numero"
              v-model="opera.numero"
              type="text"
              label="Numero"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-select
              id="tipologia"
              v-model="opera.tipologia"
              label="Tipologia Opera"
              :reduce="r => r.id"
              :options="optsTpOpera"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-select
              id="stato.statoLg"
              v-model="anagrafica.stato.statoLg"
              label="Stato LG20"
              :reduce="r => r.id"
              :options="optsStatoLg"
            />
          </b-col>
          <b-col cols="6">
            <cs-select
              id="stato.statoAinop"
              v-model="anagrafica.stato.statoAinop"
              label="Stato AINOP"
              :reduce="r => r.id"
              :options="optsStatoAinop"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="note"
              v-model="opera.note"
              label="Note"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-select
              id="anagrafica.tipologia"
              v-model="anagrafica.tipologia"
              label="Tipologia Anagrafica"
              :reduce="r => r.id"
              :options="optsTpAnagrafica"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>
export default {
  props: {
    opera: {
      type: Object,
      required: true,
    },
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsTpOpera: [
        { id: 1, label: 'PONTE' },
        { id: 2, label: 'VIADOTTO' },
        { id: 3, label: 'SOTTOVIA' },
        { id: 4, label: 'CAVALCAVIA' },
        { id: 5, label: 'SOVRAPPASSO' },
        { id: 6, label: 'SOPRAELEVATA' },
        { id: 7, label: 'GALLERIA' },
        { id: 8, label: 'TOMBINO' },
        { id: 9, label: 'CORPO STRADALE' },
        { id: 10, label: 'CORPO DI FABBRICA' },
        { id: 11, label: 'STAZIONE' },
        { id: 12, label: 'FERROVIA' },
        { id: 14, label: 'SOTTOPASSO' },
        { id: 13, label: 'ALTRA TIPOLOGIA OPERA' },
      ],
      optsTpAnagrafica: [
        { id: 1, label: 'Edificio Pubblico' },
        { id: 2, label: 'Galleria Stradale' },
        { id: 3, label: 'Ponte Viadotto Stradale' },
        { id: 4, label: 'Tratta Stradale' },
        { id: 5, label: 'Ponte Ferroviario' },
        { id: 6, label: 'Galleria Ferroviaria' },
        { id: 7, label: 'Tratta Ferroviaria' },
        { id: 8, label: 'Sottopasso' },
      ],
      optsStatoLg: [
        { id: 1, label: 'Pienamente agibile' },
        { id: 2, label: 'Agibile ma con scadenze di lavori di manutenzione ordinaria' },
        { id: 3, label: 'Agibile ma con scadenze di lavori di manutenzione straordinaria' },
        { id: 4, label: 'Condizioni critiche e agibile parzialmente/lavori di manutenzione urgenti' },
        { id: 5, label: 'Inagibile' },
      ],
      optsStatoAinop: [
        { id: 1, label: 'PIENAMENTE_AGIBILE' },
        { id: 2, label: 'AGIBILE_CON_SCADENZE_LAVORI_MANUT_ORD' },
        { id: 3, label: 'AGIBILE_CON_NECESSITA_MANUT_STRAORD' },
        { id: 4, label: 'CONDIZIONI_CRITICHE_LAVORI_MANUT_URGENTI' },
        { id: 5, label: 'INAGIBILE' },
        { id: 5, label: 'IN_ELABORAZIONE' },
        { id: 5, label: 'FUORI_SERVIZIO' },
      ],
    }
  },
}
</script>
