<template>
  <b-card header="Classi di conseguenza (secondo definizione EN 1990:2002)">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">
            <cs-select
              id="classiConseguenza.tipologia"
              v-model="anagrafica.classiConseguenza.tipologia"
              label="Tipologia"
              :reduce="r => r.id"
              :options="options"
              @input="enableMotivazione"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="classiConseguenza.giustificazione"
              v-model="anagrafica.classiConseguenza.giustificazione"
              label="In caso di classe di conseguenza minore di CC3, fornire adeguata giustificazione:"
              :read-only="!isMotivazioneEnabled"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>
const CLSCONSEGUENZA = Object.freeze({
  CC3: { id: 1, label: 'CC3 (Elevate conseguenze per perdita di vite umane, o con conseguenze molto gravi in termini economici, sociali o ambientali)' },
  CC2: { id: 2, label: 'CC2 (Conseguenze medie per perdita di vite umane, o con conseguenze considerevoli in termini economici, sociali o ambientali considerevoli)' },
  CC1: { id: 3, label: 'CC1 (Conseguenze basse per perdita di vite umane, o con conseguenze modeste o trascurabili in termini economici, sociali o ambientali)' },
})

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: Object.values(CLSCONSEGUENZA),
      isMotivazioneEnabled: false,
    }
  },
  methods: {
    enableMotivazione(params) {
      if (params !== CLSCONSEGUENZA.CC3.id) {
        this.isMotivazioneEnabled = true
      } else {
        this.isMotivazioneEnabled = false
        this.anagrafica.classiConseguenza.giustificazione = null
      }
    },
  },
}
</script>
