<template>
  <b-card header="Spalle">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="6">
            <cs-input
              id="spalle.tpSpallaIniziale"
              v-model="anagrafica.spalle.tpSpallaIniziale"
              label="Tipologia spalla iniziale"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="spalle.tpSpallaFinale"
              v-model="anagrafica.spalle.tpSpallaFinale"
              label="Tipologia spalla finale"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-input
              id="spalle.fondSpallaIniziale"
              v-model="anagrafica.spalle.fondSpallaIniziale"
              label="Fondazioni spalla iniziale"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="spalle.fondSpallaFinale"
              v-model="anagrafica.spalle.fondSpallaFinale"
              label="Fondazioni spalla finale"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
}
</script>
