<template>
  <b-card header="Morfologia Sito">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">
            <cs-select
              id="morfologia"
              v-model="anagrafica.morfologia"
              :options="optsMorfologia"
              :reduce="r => r.id"
              label="Morfologia del sito"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsMorfologia: [
        { id: 1, label: 'Cresta' },
        { id: 2, label: 'Pendio moderato (10° - 25°)' },
        { id: 3, label: 'Pendio moderato (10° - 25°)' },
        { id: 4, label: 'Pendio ripido (> 25°)' },
        { id: 5, label: 'Pendio ripido (> 25°)' },
        { id: 6, label: 'Pianura alla base dei versanti' },
      ],
    }
  },
}
</script>
