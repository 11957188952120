<template>
  <b-card header="Caratteristiche Geometriche">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="6">
            <cs-input
              id="geometria.luceComplessiva"
              v-model="anagrafica.geometria.luceComplessiva"
              type="number"
              step=".01"
              label="Luce complessiva (Estesa) [m]"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="geometria.nrCampate"
              v-model="anagrafica.geometria.nrCampate"
              type="number"
              label="N° campate"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-input
              id="geometria.larghezzaImpalcato"
              v-model="anagrafica.geometria.larghezzaImpalcato"
              type="number"
              step=".01"
              label="Larghezza totale impalcato [m]"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="geometria.luceCampate"
              v-model="anagrafica.geometria.luceCampate"
              type="number"
              step=".01"
              label="Luce campate"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-select
              id="geometria.tracciato"
              v-model="anagrafica.geometria.tracciato"
              :reduce="r => r.id"
              :options="optsTpTracciato"
              label="Tracciato"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-input
              id="geometria.superficieTotaleSx"
              v-model="anagrafica.geometria.superficieTotaleSx"
              type="number"
              step=".01"
              label="Superficie totale SX"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="geometria.superficeTotaleDx"
              v-model="anagrafica.geometria.superficeTotaleDx"
              type="number"
              step=".01"
              label="Superficie totale DX"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsTpTracciato: [
        { id: 1, label: 'Rettilineo' },
        { id: 2, label: 'In curva' },
      ],
    }
  },
}
</script>
