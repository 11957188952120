<template>
  <b-card header="Documenti disponibili inerenti alle condizioni di rischio idrogeologico">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="6">
            <cs-check-box
              id="rischi.idraulico"
              v-model="anagrafica.rischi.idraulico"
              label="Rischio idraulico"
              :inline="true"
            />
          </b-col>
          <b-col cols="6">
            <cs-check-box
              id="rischi.frane"
              v-model="anagrafica.rischi.frane"
              label="Rischio frane"
              :inline="true"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="rischi.altro"
              v-model="anagrafica.rischi.altro"
              label="Altre documentazioni (es. zonazioni da Enti locali)"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
}
</script>
