<template>
  <cs-button-modal-add
    id="modal"
    title="Aggiungi Documento"
    :loading="loading"
  >
    <b-form @submit.prevent="">

      <b-row>
        <b-col cols="12">

          <cs-select
            id="cartella"
            v-model="folder"
            label="Cartella"
            :options="options"
            :reduce="r => r.id"
            taggable
            :create-option="c => ({ id: c, label: c })"
            @input="folderSelected"
          />

        </b-col>
      </b-row>

      <b-row style="margin-bottom: 10px">
        <b-col cols="12">

          <dashboard
            :uppy="uppy"
            :props="{
              width: 850,
              height: 550,
              thumbnailWidth: 200,
              proudlyDisplayPoweredByUppy: false,
              hidePauseResumeButton: true,
              showProgressDetails: true,
              note: 'Limite caricamento massimo: 2GB',
              metaFields: [
                { id: 'name', name: 'Nome', placeholder: 'Nome' },
                { id: 'descrizione', name: 'Descrizione', placeholder: 'Descrizione' },
              ],
              theme: 'auto',
              fileManagerSelectionType: 'both'
            }"
          />

        </b-col>
      </b-row>

    </b-form>
  </cs-button-modal-add>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Uppy from '@uppy/core'
import IT from '@uppy/locales/lib/it_IT'
import XHRUpload from '@uppy/xhr-upload'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    Dashboard,
  },
  props: {
    idOpera: {
      type: String,
      required: true,
    },
    folders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      uppy: null,
      loading: false,
      folder: null,
      options: [],
    }
  },
  beforeDestroy() {
    this.uppy.close()
  },
  created() {
    this.uppy = new Uppy({
      autoProceed: false,
      restrictions: {
        requiredMetaFields: ['name'],
      },
      locale: IT,
    }).use(XHRUpload, {
      method: 'POST',
      endpoint: `${$themeConfig.axios.baseUrl}api/v1/opere/${this.idOpera}/documenti`, // use your tus endpoint here
      retryDelays: [0, 1000, 3000, 5000],
      formData: true,
      fieldName: 'file',
      headers: {
        Authorization: `Bearer ${this.$store.state.auth.AppActiveUser.access_token}`,
      },
    })

    this.uppy.on('file-added', file => {
      this.uppy.setFileMeta(file.id, { cartella: this.folder })
    })

    this.uppy.on('upload-success', (file, response) => {
      const httpStatus = response.status // HTTP status code
      const httpBody = response.body // extracted response data

      if (httpStatus === 200) this.$emit('onAdd', httpBody)
    })

    if (this.folders) this.options = this.folders.map(m => ({ id: m.text, label: m.text }))
  },
  methods: {
    folderSelected() {
      this.uppy.setMeta({ cartella: this.folder })
    },
  },
}
</script>

<style>
.uppy-DashboardContent-bar {
  z-index: 999 !important;
}
.uppy-Dashboard-Item-action {
  z-index: 999 !important;
}
</style>
