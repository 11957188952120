<template>
  <b-card header="Descrizione degli eventuali interventi strutturali eseguiti">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">
            <cs-input
              id="interventi.voltaMuratura"
              v-model="anagrafica.interventi.voltaMuratura"
              label="Volta in muratura"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="interventi.riparazioneElementiStrutturali"
              v-model="anagrafica.interventi.riparazioneElementiStrutturali"
              label="Riparazione/Sostituzione di elementi strutturali"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="interventi.ampliamentoCarregiata"
              v-model="anagrafica.interventi.ampliamentoCarregiata"
              label="Ampliamento di carreggiata e delle strutture"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="interventi.elementiStrutturaliAggiuntivi"
              v-model="anagrafica.interventi.elementiStrutturaliAggiuntivi"
              label="Elementi strutturali aggiuntivi/consolidamento"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="interventi.interventiGeotecniciSpallePile"
              v-model="anagrafica.interventi.interventiGeotecniciSpallePile"
              label="Interventi di carattere geotecnico in fondazione e/o in corrispondenza delle spalle"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="interventi.interventiMitigazioneSpallePile"
              v-model="anagrafica.interventi.interventiMitigazioneSpallePile"
              label="Interventi di mitigazione/protezione dall’erosione di spalle e pile"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="interventi.altro"
              v-model="anagrafica.interventi.altro"
              label="Altro"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
}
</script>
