<template>
  <b-card header="Attività di monitoraggio pregresse o in corso">
    <b-card-body>
      <b-form @submit.prevent="">

        <fieldset>
          <b-row>
            <b-col cols="12">
              <cs-select
                id="monitoraggio.stato"
                v-model="anagrafica.monitoraggio.stato"
                :options="options"
                :reduce="r => r.id"
                label="Stato"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <cs-input
                id="monitoraggio.tpRilevamento"
                v-model="anagrafica.monitoraggio.tpRilevamento"
                label="Tipo rilevamento"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="monitoraggio.mtMonitoraggio"
                v-model="anagrafica.monitoraggio.mtMonitoraggio"
                label="Metodologia monitoraggio"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <cs-input
                id="monitoraggio.dtInizio"
                v-model="anagrafica.monitoraggio.dtInizio"
                type="date"
                label="Data inizio"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="monitoraggio.dtAggiornamento"
                v-model="anagrafica.monitoraggio.dtAggiornamento"
                type="date"
                label="Data ultimo aggiornamento"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="monitoraggio.dtFine"
                v-model="anagrafica.monitoraggio.dtFine"
                type="date"
                label="Data fine"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="monitoraggio.tpStrumentazione"
                v-model="anagrafica.monitoraggio.tpStrumentazione"
                label="Tipologia strumentazione"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="monitoraggio.grandezzeMisurate"
                v-model="anagrafica.monitoraggio.grandezzeMisurate"
                label="Grandezze misurate"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="monitoraggio.livelloAllerta"
                v-model="anagrafica.monitoraggio.livelloAllerta"
                label="Livello Allerta"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="monitoraggio.risultatiSignificativi"
                v-model="anagrafica.monitoraggio.risultatiSignificativi"
                label="Risultati significativi"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-select
                id="monitoraggio.allegato"
                v-model="anagrafica.monitoraggio.allegato"
                label="Allegato"
                :options="documenti"
                option-label="nome"
              />
            </b-col>
          </b-row>
        </fieldset>
      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

export default {
  props: {
    documenti: {
      type: Array,
      required: true,
    },
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [
        { id: 1, label: 'Presenti' },
        { id: 2, label: 'Assenti' },
        { id: 3, label: 'Non Note' },
      ],
      isPeriodEnabled: false,
    }
  },
}
</script>
