<template>
  <div>
    <b-card header="Localizzazione">
      <b-card-body>
        <b-form @submit.prevent="">

          <fieldset class="mb-2">
            <b-row>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.kmIniziale"
                  v-model="anagrafica.localizzazione.kmIniziale"
                  label="Progressiva km iniziale"
                />
              </b-col>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.kmFinale"
                  v-model="anagrafica.localizzazione.kmFinale"
                  label="Progressiva km finale"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.regione"
                  v-model="anagrafica.localizzazione.regione"
                  label="Regione"
                />
              </b-col>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.provincia"
                  v-model="anagrafica.localizzazione.provincia"
                  label="Provincia"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.comune"
                  v-model="anagrafica.localizzazione.comune"
                  label="Comune"
                />
              </b-col>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.localita"
                  v-model="anagrafica.localizzazione.localita"
                  label="Località"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.sismicita"
                  v-model="anagrafica.localizzazione.sismicita"
                  type="number"
                  step=".01"
                  label="Sismicità dell’area [ag/g] (Suolo A, TR = 475 anni)"
                />
              </b-col>
              <b-col cols="6">
                <cs-select
                  id="localizzazione.zonaSismicita"
                  v-model="anagrafica.localizzazione.zonaSismicita"
                  label="Zona di sismicità"
                  :reduce="r => r.id"
                  :options="optsZonaSismicita"
                />
              </b-col>
            </b-row>
          </fieldset>
          <b-row>
            <b-col cols="6">
              <cs-select
                id="localizzazione.fenomeniErosivi"
                v-model="anagrafica.localizzazione.fenomeniErosivi"
                :options="optsFenomeniErosivi"
                :reduce="r => r.id"
                label="Fenomeni erosivi e di alluvionamento"
              />
            </b-col>
            <b-col cols="6">
              <cs-select
                id="localizzazione.fenomeniErosivi"
                v-model="anagrafica.localizzazione.fenomeniFranosi"
                :options="optsFenomeniFranosi"
                :reduce="r => r.id"
                label="Fenomeni franosi"
              />
            </b-col>
          </b-row>

        </b-form>
      </b-card-body>
    </b-card>
    <b-card header="Coordinate Geografiche">
      <b-card-body>
        <b-form @submit.prevent="">

          <b-row>
            <b-col cols="12">
              <cs-select
                id="localizzazione.tpCoordinateId"
                v-model="anagrafica.localizzazione.tpCoordinateId"
                :options="optsTpCoordinate"
                :reduce="r => r.id"
                label="Tipologia"
              />
            </b-col>
          </b-row>
          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Centro
            </legend>
            <b-row>
              <b-col cols="12">
                <cs-input
                  id="localizzazione.centro.quotaSulMare"
                  v-model="anagrafica.localizzazione.centro.quotaSulMare"
                  type="number"
                  step=".01"
                  label="Quota s.l.m. [m]:"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.centro.longitudine"
                  v-model="anagrafica.localizzazione.centro.longitudine"
                  type="number"
                  step=".01"
                  label="Longitudine"
                />
              </b-col>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.centro.latitudine"
                  v-model="anagrafica.localizzazione.centro.latitudine"
                  type="number"
                  step=".01"
                  label="Latitudine"
                />
              </b-col>
            </b-row>
          </fieldset>
          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Iniziale
            </legend>
            <b-row>
              <b-col cols="12">
                <cs-input
                  id="localizzazione.iniziale.quotaSulMare"
                  v-model="anagrafica.localizzazione.iniziale.quotaSulMare"
                  type="number"
                  step=".01"
                  label="Quota s.l.m. [m]:"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.iniziale.longitudine"
                  v-model="anagrafica.localizzazione.iniziale.longitudine"
                  type="number"
                  step=".01"
                  label="Longitudine"
                />
              </b-col>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.iniziale.latitudine"
                  v-model="anagrafica.localizzazione.iniziale.latitudine"
                  type="number"
                  step=".01"
                  label="Latitudine"
                />
              </b-col>
            </b-row>
          </fieldset>
          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Finale
            </legend>
            <b-row>
              <b-col cols="12">
                <cs-input
                  id="localizzazione.finale.quotaSulMare"
                  v-model="anagrafica.localizzazione.finale.quotaSulMare"
                  type="number"
                  step=".01"
                  label="Quota s.l.m. [m]:"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.finale.longitudine"
                  v-model="anagrafica.localizzazione.finale.longitudine"
                  type="number"
                  step=".01"
                  label="Longitudine"
                />
              </b-col>
              <b-col cols="6">
                <cs-input
                  id="localizzazione.finale.latitudine"
                  v-model="anagrafica.localizzazione.finale.latitudine"
                  type="number"
                  step=".01"
                  label="Latitudine"
                />
              </b-col>
            </b-row>
          </fieldset>

        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsZonaSismicita: [
        { id: 1, label: 'ZONA 1: Massimo Pericolo' },
        { id: 2, label: 'ZONA 2: Possibili forti terremoti' },
        { id: 3, label: 'ZONA 3: Minore probabilità di forti terremoti' },
        { id: 4, label: 'ZONA 4: Zona con minor pericolo' },
      ],
      optsFenomeniErosivi: [
        { id: 1, label: 'Assenti' },
        { id: 2, label: 'Già Valutati' },
        { id: 3, label: 'Da Verificare' },
      ],
      optsFenomeniFranosi: [
        { id: 1, label: 'Assenti' },
        { id: 2, label: 'Già Valutati' },
        { id: 3, label: 'Da Verificare' },
      ],
      optsTpCoordinate: [
        { id: 1, label: 'ETRF2000' },
        { id: 2, label: 'WGS84' },
      ],
    }
  },
}
</script>
