<template>
  <div>
    <b-card header="Informazioni Generali">
      <b-card-body>
        <b-form @submit.prevent="">

          <b-row>
            <b-col cols="12">
              <cs-input
                id="generali.proprietario"
                v-model="anagrafica.generali.proprietario"
                label="Proprietario"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="generali.concessionario"
                v-model="anagrafica.generali.concessionario"
                label="Concessionario"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="generali.enteVigilante"
                v-model="anagrafica.generali.enteVigilante"
                label="Ente vigilante"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="generali.idGestoreProprietario"
                v-model="anagrafica.generali.idGestoreProprietario"
                label="Id per gestore o proprietario"
              />
            </b-col>
          </b-row>

        </b-form>
      </b-card-body>
    </b-card>
    <b-card header="Anno di Costruzione/Ristrutturazione">
      <b-card-body>
        <b-form @submit.prevent="">

          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Lavori
            </legend>

            <b-row>
              <b-col cols="4">
                <cs-input
                  id="generali.dtInizioLavori"
                  v-model="anagrafica.generali.dtInizioLavori"
                  type="number"
                  label="Anno inizio lavori"
                />
              </b-col>
              <b-col cols="4">
                <cs-input
                  id="generali.dtFineLavori"
                  v-model="anagrafica.generali.dtFineLavori"
                  type="number"
                  label="Anno fine lavori"
                />
              </b-col>
              <b-col cols="4">
                <cs-input
                  id="generali.dtEntrataEsercizio"
                  v-model="anagrafica.generali.dtEntrataEsercizio"
                  type="number"
                  label="Anno entrata in esercizio"
                />
              </b-col>
            </b-row>

          </fieldset>
          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Ultimazione costruzione (collaudo)
            </legend>

            <b-row>
              <b-col cols="12">
                <cs-input
                  id="generali.dtCollaudo.anno"
                  v-model="anagrafica.generali.dtCollaudo.anno"
                  type="number"
                  label="Anno"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <cs-select
                  id="generali.dtCollaudo.tipologia"
                  v-model="anagrafica.generali.dtCollaudo.tipologia"
                  :reduce="r => r.id"
                  :options="optsTpAnno"
                  label="Tipologia"
                />
              </b-col>
            </b-row>
          </fieldset>
          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Eventuali interventi sostanziali
            </legend>

            <b-row>
              <b-col cols="12">
                <cs-input
                  id="generali.dtInterventi.anno"
                  v-model="anagrafica.generali.dtInterventi.anno"
                  type="number"
                  label="Anno"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <cs-select
                  id="generali.dtInterventi.tipologia"
                  v-model="anagrafica.generali.dtInterventi.tipologia"
                  :reduce="r => r.id"
                  :options="optsTpAnno"
                  label="Tipologia"
                />
              </b-col>
            </b-row>
          </fieldset>

        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import TPANNO from '@/views/opere/dettaglio/anagrafica/enums/tipologiaAnno'

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsTpAnno: Object.values(TPANNO),
    }
  },
}
</script>
