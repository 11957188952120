<template>
  <b-card header="Tutela ai sensi del Decreto Legislativo 22 gennaio 2004, n. 42">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">
            <cs-input
              id="decreto.provvedimentoTutela"
              v-model="anagrafica.decreto.provvedimentoTutela"
              label="Provvedimenti di tutela"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="decreto.autoreProgettazione"
              v-model="anagrafica.decreto.autoreProgettazione"
              label="Autore progettazione"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="decreto.pianiPaesaggistici"
              v-model="anagrafica.decreto.pianiPaesaggistici"
              label="Inserimento del ponte nell’ambito dei Piani Paesaggistici vigenti/adottati"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
}
</script>
