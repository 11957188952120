var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cs-button-modal-add',{attrs:{"id":"modal","title":"Aggiungi Documento","loading":_vm.loading}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('cs-select',{attrs:{"id":"cartella","label":"Cartella","options":_vm.options,"reduce":function (r) { return r.id; },"taggable":"","create-option":function (c) { return ({ id: c, label: c }); }},on:{"input":_vm.folderSelected},model:{value:(_vm.folder),callback:function ($$v) {_vm.folder=$$v},expression:"folder"}})],1)],1),_c('b-row',{staticStyle:{"margin-bottom":"10px"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('dashboard',{attrs:{"uppy":_vm.uppy,"props":{
            width: 850,
            height: 550,
            thumbnailWidth: 200,
            proudlyDisplayPoweredByUppy: false,
            hidePauseResumeButton: true,
            showProgressDetails: true,
            note: 'Limite caricamento massimo: 2GB',
            metaFields: [
              { id: 'name', name: 'Nome', placeholder: 'Nome' },
              { id: 'descrizione', name: 'Descrizione', placeholder: 'Descrizione' } ],
            theme: 'auto',
            fileManagerSelectionType: 'both'
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }