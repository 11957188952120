<template>
  <div>
    <b-card header="Dati di Progetto">
      <b-card-body>
        <b-form @submit.prevent="">

          <b-row>
            <b-col cols="12">
              <cs-input
                id="progetto.progettista"
                v-model="anagrafica.progetto.progettista"
                label="Proprietario"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="progetto.normeDiProgetto"
                v-model="anagrafica.progetto.normeDiProgetto"
                label="Norme di progetto"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="progetto.enteApprovatore"
                v-model="anagrafica.progetto.enteApprovatore"
                label="Ente approvatore"
              />
            </b-col>
          </b-row>

        </b-form>
      </b-card-body>
    </b-card>
    <b-card header="Anno di Progettazione">
      <b-card-body>
        <b-form @submit.prevent="">

          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Data inizio
            </legend>

            <b-row>
              <b-col cols="12">
                <cs-input
                  id="progetto.dtInizio.anno"
                  v-model="anagrafica.progetto.dtInizio.anno"
                  type="number"
                  label="Anno"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <cs-select
                  id="progetto.dtInizio.tipologia"
                  v-model="anagrafica.progetto.dtInizio.tipologia"
                  :reduce="r => r.id"
                  :options="optsTpAnno"
                  label="Tipologia"
                />
              </b-col>
            </b-row>
          </fieldset>
          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Data fine
            </legend>

            <b-row>
              <b-col cols="12">
                <cs-input
                  id="progetto.dtFine.anno"
                  v-model="anagrafica.progetto.dtFine.anno"
                  type="number"
                  label="Anno"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <cs-select
                  id="progetto.dtFine.tipologia"
                  v-model="anagrafica.progetto.dtFine.tipologia"
                  :reduce="r => r.id"
                  :options="optsTpAnno"
                  label="Tipologia"
                />
              </b-col>
            </b-row>
          </fieldset>
          <fieldset class="mb-1 border cs-border p-2">
            <legend class="cs-legend">
              Data approvazione
            </legend>

            <b-row>
              <b-col cols="12">
                <cs-input
                  id="progetto.dtApprovazione.anno"
                  v-model="anagrafica.progetto.dtApprovazione.anno"
                  type="number"
                  label="Anno"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <cs-select
                  id="progetto.dtApprovazione.tipologia"
                  v-model="anagrafica.progetto.dtApprovazione.tipologia"
                  :reduce="r => r.id"
                  :options="optsTpAnno"
                  label="Tipologia"
                />
              </b-col>
            </b-row>
          </fieldset>

        </b-form>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>

import TPANNO from '@/views/opere/dettaglio/anagrafica/enums/tipologiaAnno'

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsTpAnno: Object.values(TPANNO),
    }
  },
}
</script>
