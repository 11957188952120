<template>
  <div>
    <b-card header="Interventi di manutenzione">
      <b-card-body>
        <b-form @submit.prevent="">

          <b-row>
            <b-col cols="12">
              <cs-select
                id="interventiManutenzione.stato"
                v-model="anagrafica.manutenzioni.stato"
                :options="optsStManutenzione"
                :reduce="r => r.id"
                label="Stato"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="interventiManutenzione.piano"
                v-model="anagrafica.manutenzioni.piano"
                label="Piano di manutenzione"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <cs-input
                id="interventiManutenzione.numero"
                v-model="anagrafica.manutenzioni.numero"
                type="number"
                label="Numero di interventi manutentivi effettuati"
                @input="enablePeriod"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="interventiManutenzione.dataUltimo"
                v-model="anagrafica.manutenzioni.dtUltimo"
                type="date"
                label="Data ultimo intervento"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="interventiManutenzione.periodicita"
                v-model="anagrafica.manutenzioni.periodicita"
                :read-only="!isPeriodEnabled"
                label="Periodicità (se più di 1 intervento)"
              />
            </b-col>
          </b-row>

        </b-form>
      </b-card-body>
    </b-card>
    <cs-table-card
      :rows="anagrafica.manutenzioni.interventi"
      :columns="columns"
      card-header="Interventi"
    >
      <!-- TODO: Table -->

      <!--      <template-->
      <!--        slot="table-row"-->
      <!--        slot-scope="props"-->
      <!--      >-->
      <!--        <cs-table-action-column-default-->
      <!--          :show-actions="canDetails || canIspezioni || canDelete"-->
      <!--          :props="props"-->
      <!--        >-->
      <!--          &lt;!&ndash;            <cs-table-action-item&ndash;&gt;-->
      <!--          &lt;!&ndash;              value="Bim"&ndash;&gt;-->
      <!--          &lt;!&ndash;              icon="BoxIcon"&ndash;&gt;-->
      <!--          &lt;!&ndash;              :row="props.row"&ndash;&gt;-->
      <!--          &lt;!&ndash;              @click="bimRow"&ndash;&gt;-->
      <!--          &lt;!&ndash;            />&ndash;&gt;-->
      <!--          <cs-table-action-item-details-->
      <!--            :show="canDetails"-->
      <!--            :row="props.row"-->
      <!--            @click="detailsRow"-->
      <!--          />-->
      <!--          <cs-table-action-item-->
      <!--            :show="canIspezioni"-->
      <!--            value="Ispezioni"-->
      <!--            icon="FileTextIcon"-->
      <!--            :row="props.row"-->
      <!--            @click="ispezioniRow"-->
      <!--          />-->
      <!--          <cs-table-action-item-delete-->
      <!--            :show="canDelete"-->
      <!--            :row="props.row"-->
      <!--            @click="removeRow"-->
      <!--          />-->
      <!--        </cs-table-action-column-default>-->

      <!--      </template>-->
    </cs-table-card>
  </div>

</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Numero',
          field: 'numero',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Data',
          field: 'data',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Descrizione',
          field: 'descrizione',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Allegato',
          field: 'allegato',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      optsStManutenzione: [
        { id: 1, label: 'Presenti' },
        { id: 2, label: 'Assenti' },
        { id: 3, label: 'Non Noti' },
      ],
      isPeriodEnabled: false,
    }
  },
  methods: {
    enablePeriod() {
      this.isPeriodEnabled = this.anagrafica.manutenzioni.numero > 1
      if (!this.isPeriodEnabled) this.anagrafica.manutenzioni.periodicita = null
    },
  },
}
</script>
