<template>
  <b-overlay :show="loading">
    <b-row class="pt-1">
      <b-col cols="3">
        <b-row>
          <b-col cols="12">
            <b-card header="Azioni">
              <div style="padding-top: 1rem">
                <b-row align-h="between">
                  <b-col cols="6">
                    <aggiungi-documento
                      v-if="canAdd"
                      :id-opera="opera.id"
                      style="margin-right: 5px;"
                      :folders="folders"
                      @onAdd="addRow"
                    />

                    <b-button
                      style="margin-right: 5px; margin-bottom: 5px; margin-top: 5px;"
                      variant="primary"
                      title="Tutte"
                      @click="openFolder()"
                    >
                      <feather-icon icon="ListIcon" />
                    </b-button>
                  </b-col>

                  <!--                  <b-col-->
                  <!--                    cols="6"-->
                  <!--                    class="d-flex justify-content-end"-->
                  <!--                  >-->
                  <!--                    <b-button-->
                  <!--                      v-if="currentFolder"-->
                  <!--                      style="margin-right: 5px; margin-bottom: 5px; margin-top: 5px;"-->
                  <!--                      variant="primary"-->
                  <!--                      title="Rinomina Cartella"-->
                  <!--                      @click="renameFolder()"-->
                  <!--                    >-->
                  <!--                      <feather-icon icon="Edit3Icon" />-->
                  <!--                    </b-button>-->
                  <!--                  </b-col>-->
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card header="Cartelle">
              <div style="padding-top: 1rem">
                <liquor-tree
                  ref="tree"
                  :data="folders"
                >
                  <div
                    slot-scope="{ node }"
                    style="width: 100%"
                    class="tree-text"
                    @click="openFolder(node)"
                  >
                    <template>
                      <feather-icon
                        icon="FolderIcon"
                        style="margin-right: 2px; margin-bottom: 4px;"
                      />
                      {{ node.text }}
                    </template>

                  </div>
                </liquor-tree>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="9">
        <CsTableCard
          :rows="rows"
          :columns="columns"
          card-header="Elenco Documenti"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <cs-table-action-column-default
              :props="props"
              :show-actions="canDownload || canRemove"
            >

              <cs-table-action-item-download
                :show="canDownload"
                :row="props.row"
                @click="downloadRow"
              />

              <cs-table-action-item-delete
                :show="canRemove"
                :row="props.row"
                @click="removeRow"
              />
            </cs-table-action-column-default>

          </template>

        </CsTableCard>
      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>
import LiquorTree from 'liquor-tree'
import AggiungiDocumento from './Aggiungi.vue'

export default {
  components: {
    AggiungiDocumento,
    LiquorTree,
  },
  props: {
    opera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'Cartella',
          field: 'cartella',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Nome',
          field: 'nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Descrizione',
          field: 'descrizione',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      canRemove: this.$grants.OPERE_DOCUMENTI_ELIMINA.can,
      canAdd: this.$grants.OPERE_DOCUMENTI_AGGIUNGI.can,
      canDownload: this.$grants.OPERE_DOCUMENTI_SCARICA.can,
      folders: [],
      currentFolder: null,
    }
  },
  created() {
    this.computeFolders()
  },
  methods: {
    computeFolders() {
      const grouped = this.groupBy(this.opera.documenti, m => m.cartella)
      const tempFolders = Array.from(grouped).map(([key]) => ({ text: key }))

      const newFolders = tempFolders.filter(f => !this.folders.map(m => m.text).includes(f.text))
      const oldFolders = this.folders.filter(f => !tempFolders.map(m => m.text).includes(f.text))

      if (!this.$refs.tree) this.folders = newFolders
      else {
        newFolders.every(n => this.$refs.tree.append(n))
        oldFolders.every(n => this.$refs.tree.remove(n))
        this.folders = tempFolders
      }
    },
    openFolder(node) {
      if (this.isNullOrUndefined(node)) this.currentFolder = null
      else this.currentFolder = node.data.text

      if (!this.currentFolder) {
        this.rows = this.opera.documenti
        const sel = this.$el.querySelector('.tree-node.selected')
        if (sel) sel.classList.remove('selected')
      } else {
        const grouped = this.groupBy(this.opera.documenti, d => d.cartella, this.currentFolder)
        this.rows = Array.from(grouped)['0']['1']
        const sel = this.$el.querySelector(`li[data-id="${node.id}"]`)
        if (sel && !sel.classList.contains('selected')) sel.classList.add('selected')
      }
    },
    // renameFolder() {
    //
    // },
    addRow(params) {
      this.addRowAtStart(this.opera.documenti, params)
      this.computeFolders()
      this.openFolder(this.currentFolder)
      // this.showAddSuccessMessage()
    },
    downloadRow(params) {
      this.loading = true
      this.$remote.opere.documento_download(this.opera.id, params.id)
        .then(response => {
          this.downloadFile(response.data, params.nome)
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.opere.documento_remove(this.opera.id, params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)

            const doc = this.opera.documenti.filter(f => f.id === params.id)[0]
            this.deleteRow(this.opera.documenti, this.opera.documenti.indexOf(doc))

            this.computeFolders()
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
  },
}

</script>
