<template>
  <b-card header="Giunti">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="6">
            <cs-input
              id="giunti.tipologia"
              v-model="anagrafica.giunti.tipologia"
              label="Tipologia giunti"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="giunti.numero"
              v-model="anagrafica.giunti.numero"
              type="number"
              label="Numero totale giunti"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="giunti.lunghezza"
              v-model="anagrafica.giunti.lunghezza"
              type="number"
              step=".01"
              label="Lunghezza"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-input
              id="giunti.lunghezzaGiuntoSpalla"
              v-model="anagrafica.giunti.lunghezzaGiuntoSpalla"
              type="number"
              step=".01"
              label="Lunghezza giunto spalla [m]"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="giunti.lunghezzaGiuntoPila"
              v-model="anagrafica.giunti.lunghezzaGiuntoPila"
              type="number"
              step=".01"
              label="Lunghezza giunto pila [m]"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
}
</script>
