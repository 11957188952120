<template>
  <b-overlay :show="loading">
    <b-card header="Carica ifc">
      <b-card-body class="pb-0">

        <validation-observer ref="rules">
          <b-form @submit.prevent="">

            <b-row>
              <b-col cols="10">
                <cs-validation
                  label="Bim"
                  rules="required"
                >
                  <template slot-scope="props">

                    <b-form-file
                      v-model="file"
                      accept=".ifc"
                      placeholder="Seleziona o trascina un ifc qui..."
                      drop-placeholder="Trascina un ifc qui..."
                      :state="props.errors.length > 0 ? false : null"
                    />
                  </template>
                </cs-validation>

              </b-col>
              <b-col cols="2">
                <cs-submit-button :click="add">
                  Carica
                </cs-submit-button>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>

export default {
  props: {
    opera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      file: null,
    }
  },
  methods: {
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.opere.bim_load(this.opera.id, this.file)
            .then(res => {
              this.$emit('onAdd', res.data)
            })
            .catch(res => {
              this.showReposonseMessage(res)
            })
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
