<template>
  <CsTableCard
    card-header="Elenco Opere Parziali"
    :rows="rows"
    :columns="columns"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :props="props"
        :show-actions="canParti"
      >
        <cs-table-action-item-details
          :show="canParti"
          :row="props.row"
          @click="detailsRow"
        />
      </cs-table-action-column-default>

    </template>
  </CsTableCard>
</template>

<script>
export default {
  props: {
    opera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Codice',
          field: 'codice',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Numero Opera Parziale',
          field: 'numero',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      canParti: this.$grants.OPERE_PARTI_VISUALIZZA.can,
    }
  },
  created() {
    this.rows = this.opera.parziali
  },
  methods: {
    detailsRow(params) {
      this.$routing.OPERE_PARZIALI_DETTAGLIO.push({ id: this.opera.id, operaParzialeId: params.id })
    },
  },
}
</script>
