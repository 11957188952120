<template>
  <b-card header="Rete stradale">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">
            <cs-input
              id="reteStradale.categoriaPonte"
              v-model="anagrafica.reteStradale.categoriaPonte"
              label="Categoria ponte"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <cs-check-box
              id="reteStradale.itinerarioInternazionale"
              v-model="anagrafica.reteStradale.itinerarioInternazionale"
              label="Itinerario Internazionale"
              :inline="true"
            />
          </b-col>
          <b-col cols="3">
            <cs-check-box
              id="reteStradale.reteTen"
              v-model="anagrafica.reteStradale.reteTen"
              label="Rete TEN"
              :inline="true"
            />
          </b-col>
          <b-col cols="3">
            <cs-check-box
              id="reteStradale.reteEmergenza"
              v-model="anagrafica.reteStradale.reteEmergenza"
              label="Rete emergenza"
              :inline="true"
            />
          </b-col>
          <b-col cols="3">
            <cs-check-box
              id="reteStradale.presenzaCurve"
              v-model="anagrafica.reteStradale.presenzaCurve"
              label="Presenza di curve"
              :inline="true"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-input
              id="reteStradale.nrCarregiate"
              v-model="anagrafica.reteStradale.nrCarregiate"
              label="N° di carreggiate"
              type="number"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="reteStradale.nrCorsie"
              v-model="anagrafica.reteStradale.nrCorsie"
              label="N° corsie/carreggiata"
              type="number"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="reteStradale.trafficoAvgGg"
              v-model="anagrafica.reteStradale.trafficoAvgGg"
              label="Traffico Medio Giornaliero - veicoli/giorno (sull’intera carreggiata)"
              type="number"
              step=".01"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-input
              id="reteStradale.trafficoAvgGgVeicoliCmCarregiata"
              v-model="anagrafica.reteStradale.trafficoAvgGgVeicoliCmCarregiata"
              label="Traffico Medio Giornaliero – Veicoli commerciali* - veicoli/giorno (sull’intera carreggiata)"
              type="number"
              step=".01"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="reteStradale.trafficoAvgGgVeicoliCmCorsia"
              v-model="anagrafica.reteStradale.trafficoAvgGgVeicoliCmCorsia"
              label="Traffico Medio Giornaliero – Veicoli commerciali* - veicoli/giorno (per singola corsia di marcia)"
              type="number"
              step=".01"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-select
              id="reteStradale.limitazioneCarico"
              v-model="anagrafica.reteStradale.limitazioneCarico"
              label="Limitazione di carico"
              :options="optsLimitazioneCarico"
              :reduce="r => r.id"
              @input="enableMassa"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="reteStradale.massaLimitazioneCarico"
              v-model="anagrafica.reteStradale.massaLimitazioneCarico"
              label="Massima massa consentita (ton)"
              type="number"
              step=".01"
              :read-only="!isMassaEnabled"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-check-box
              id="reteStradale.alternativeStradali"
              v-model="anagrafica.reteStradale.alternativeStradali"
              label="Presenza di alternative stradali (in caso di chiusure/limitazioni di traffico)"
              :inline="true"
            />
          </b-col>
          <b-col cols="6">
            <cs-check-box
              id="reteStradale.disponibilitaStudi"
              v-model="anagrafica.reteStradale.disponibilitaStudi"
              label="Disponibilità di studi trasportistici specifici"
              :inline="true"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <cs-input
              id="reteStradale.durataDeviazioneKm"
              v-model="anagrafica.reteStradale.durataDeviazioneKm"
              label="Durata deviazione Km"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="reteStradale.durataDeviazioneMn"
              v-model="anagrafica.reteStradale.durataDeviazioneMn"
              label="Durata deviazione Minuti"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="reteStradale.catgoriaPercorsoAlternativo"
              v-model="anagrafica.reteStradale.catgoriaPercorsoAlternativo"
              label="Categoria del percorso alternativo individuato"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-select
              id="reteStradale.allegato"
              v-model="anagrafica.reteStradale.allegato"
              label="Allegato"
              :options="documenti"
              option-label="nome"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>

</template>

<script>
const LIMITCARICO = Object.freeze({
  A: {
    id: 1, label: 'Nessuna limitazione presente', codice: 1, text: 'Nessuna limitazione presente', value: 1,
  },
  ton: {
    id: 2, label: 'Massima Massa Consentita', codice: 2, text: 'Massima Massa Consentita', value: 2,
  },
})

export default {
  props: {
    documenti: {
      type: Array,
      required: true,
    },
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsLimitazioneCarico: Object.values(LIMITCARICO),
      isMassaEnabled: false,
    }
  },
  methods: {
    enableMassa(params) {
      if (params === LIMITCARICO.ton.id) {
        this.isMassaEnabled = true
      } else {
        this.isMassaEnabled = false
        this.anagrafica.reteStradale.massaLimitazioneCarico = null
      }
    },
  },
}
</script>
