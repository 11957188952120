<template>
  <b-card header="Classificazione del collegamento e Classificazione d’uso stradale">

    <b-card-body>

      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">
            <cs-select
              id="classificazioni.clsCollegamento"
              v-model="anagrafica.classificazioni.clsCollegamento"
              :options="optsClsCollegamento"
              :reduce="r => r.id"
              label="Tipo di collegamento"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-select
              id="classificazioni.clsStradale"
              v-model="anagrafica.classificazioni.clsStradale"
              :options="optsClsStradale"
              :reduce="r => r.id"
              label="Classificazione d'uso stradale"
            />
          </b-col>
        </b-row>

      </b-form>

    </b-card-body>
  </b-card>
</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optsClsCollegamento: [
        { id: 1, label: 'Ponte su corso d’acqua reticolo principale' },
        { id: 2, label: 'Ponte su corso d’acqua reticolo secondario' },
        { id: 3, label: 'Ponte su specchi d’acqua marini' },
        { id: 4, label: 'Viadotto su zona edificata' },
        { id: 5, label: 'Viadotto su zona urbanizzata' },
        { id: 6, label: 'Viadotto su altra via di comunicazione' },
        { id: 7, label: 'Ponte/Viadotto su discontinuità orografica (vallata, piccoli canali, ecc.)' },
        { id: 8, label: 'Ponte su ferrovia' },
      ],
      optsClsStradale: [
        { id: 1, label: 'Autostrada o Ferrovia' },
        { id: 2, label: 'Strada extraurbana principale' },
        { id: 3, label: 'Strada extraurbana secondaria' },
        { id: 4, label: 'Strada urbana di scorrimento' },
        { id: 5, label: 'Strada urbana di quartiere' },
        { id: 6, label: 'Strada locale' },
      ],
    }
  },
}
</script>
