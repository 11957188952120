<template>
  <b-card header="Infrastruttura">
    <b-card-body>
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="6">
            <cs-input
              id="infrastruttura.codice"
              v-model="anagrafica.infrastruttura.codice"
              label="Codice"
            />
          </b-col>
          <b-col cols="6">
            <cs-input
              id="infrastruttura.nome"
              v-model="anagrafica.infrastruttura.nome"
              label="Nome"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cs-select
              id="infrastruttura.tipologia"
              v-model="anagrafica.infrastruttura.tipologia"
              :options="options"
              :reduce="r => r.id"
              label="Tipologia"
            />
          </b-col>
        </b-row>

      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>

export default {
  props: {
    anagrafica: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [
        { id: 1, label: 'AUTOSTRADA' },
        { id: 2, label: 'STRADA STATALE' },
        { id: 3, label: 'STRADA REGIONALE' },
        { id: 4, label: 'STRADA PROVINCIALE' },
        { id: 5, label: 'STRADA COMUNALE' },
        { id: 6, label: 'STRADA VICINALE' },
        { id: 7, label: 'ALTRA INFRASTRUTTURA STRADALE' },
        { id: 8, label: 'FERROVIA ALTA VELOCITA' },
        { id: 9, label: 'FERROVIA FONDAMENTALE' },
        { id: 10, label: 'FERROVIA COMPLEMENTARE' },
        { id: 11, label: 'FERROVIA NODI' },
        { id: 12, label: 'FERROVIA A CREMAGLIERA' },
        { id: 13, label: 'FERROVIA METROPOLITANA' },
        { id: 14, label: 'ALTRA INFRASTRUTTURA FERROVIARIA' },
        { id: 15, label: 'EDILIZIA RESIDENZIALE' },
        { id: 16, label: 'EDILIZIA PENITENZIARIA' },
        { id: 17, label: 'EDILIZIA SANITARIA' },
        { id: 18, label: 'EDILIZIA MILITARE' },
        { id: 19, label: 'EDILIZIA_GIUDIZIARIA' },
        { id: 20, label: 'EDILIZIA MUSEALE' },
        { id: 21, label: 'EDILIZIA SCOLASTICA' },
      ],
    }
  },
}
</script>
